// extracted by mini-css-extract-plugin
export var menu = "navigation-module--menu--p46AS";
export var menuopen = "navigation-module--menuopen--XiflE navigation-module--menu--p46AS";
export var menuclosed = "navigation-module--menuclosed--z24aT navigation-module--menu--p46AS";
export var link = "navigation-module--link--sqcmv";
export var active = "navigation-module--active--CAy3t";
export var item = "navigation-module--item--y-DfK";
export var bar = "navigation-module--bar--hW7T7";
export var navigation = "navigation-module--navigation--Y7+yf";
export var logo = "navigation-module--logo--ovzyO";
export var hamburger = "navigation-module--hamburger--Hs2gp";
export var icon = "navigation-module--icon--s5Xel";